import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Avatar from '@material-ui/core/Avatar'
import AvatarCard from './AvatarCard'
import { default as LinkedIn } from '@material-ui/icons/LinkedIn'
import { default as Facebook } from '@material-ui/icons/Facebook'
import IconButton from '@material-ui/core/IconButton'
import NavMenu from './NavMenu'

const drawerWidth = 300

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		backgroundColor: theme.palette.primary.main,
	},
	drawerPaper: {
		width: drawerWidth,
		backgroundColor: theme.palette.primary.main,
		color: 'white',
		paddingTop: theme.spacing(4),
	},
	avatar: {
		height: '200px',
		width: drawerWidth,
		marginBottom: theme.spacing(2),
	},
	content: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.default,
	},
	footer: {
		display: 'flex',
		marginTop: 'calc(5% + 60px)',
		bottom: 0,
		padding: theme.spacing(2),
	},
}))

function Copyright() {
	return (
		<Typography variant='body1' color='inherit'>
			{'Copyright © '}
			<Link color='inherit' href='https://www.leo-koenig.com/'>
				Leo Koenig
			</Link>{' '}
			{new Date().getFullYear()}
		</Typography>
	)
}

const SideBar = ({ children }) => {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<Drawer
				className={classes.drawer}
				variant='permanent'
				classes={{
					paper: classes.drawerPaper,
				}}
				anchor='left'
			>
				<Grid container spacing={1} className={classes.avatar}>
					<Grid container alignItems={'center'} justify={'center'}>
						<AvatarCard />
					</Grid>
					<Grid container alignItems={'center'} justify={'center'}>
						<Typography variant={'h4'}>Leo Koenig</Typography>
					</Grid>
				</Grid>
				<Grid container spacing={1} justify={'center'} direction={'row'}>
					<IconButton>
						<Avatar style={{ backgroundColor: '#2c2f3f' }}>
							<Link href={'https://www.linkedin.com/in/ljkoenig/'} color={'inherit'} target={'_blank'} rel={'noreferrer'}>
								<LinkedIn />
							</Link>
						</Avatar>
					</IconButton>
					<IconButton>
						<Avatar style={{ backgroundColor: '#2c2f3f' }}>
							<Link href={'https://www.facebook.com/ljkoenig'} color={'inherit'} target={'_blank'} rel={'noreferrer'}>
								<Facebook />
							</Link>
						</Avatar>
					</IconButton>
				</Grid>
				<Grid item xs={12}>
					<NavMenu />
				</Grid>
				<Grid container justify={'center'} alignContent={'center'}>
					<footer className={classes.footer}>{Copyright()}</footer>
				</Grid>
			</Drawer>
			<main className={classes.content}>{children}</main>
		</div>
	)
}

export default SideBar
