import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ListItemText from '@material-ui/core/ListItemText'
import { default as Home } from '@material-ui/icons/Home'
import { default as About } from '@material-ui/icons/AccountCircle'
import { default as Resume } from '@material-ui/icons/Description'
import { default as Portfolio } from '@material-ui/icons/Web'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	root: {
		color: '#a8a9b4',
		fontSize: theme.typography.fontSize * 1.5,
	},
	avatar: {
		fontSize: theme.typography.fontSize * 1.5,
		backgroundColor: '#2c2f3f',
	},
	icon: {
		fontSize: theme.typography.fontSize * 2,
	},
}))

const NavMenu = () => {
	const classes = useStyles()

	return (
		<>
			<List className={classes.root}>
				<ListItem button>
					<ListItemAvatar>
						<Avatar className={classes.avatar}>
							<Home className={classes.icon} />
						</Avatar>
					</ListItemAvatar>
					<ListItemText classes={{ primary: classes.root }}>Home</ListItemText>
				</ListItem>
				<ListItem button>
					<ListItemAvatar>
						<Avatar className={classes.avatar}>
							<About className={classes.icon} />
						</Avatar>
					</ListItemAvatar>
					<ListItemText classes={{ primary: classes.root }}>About</ListItemText>
				</ListItem>
				<ListItem button>
					<ListItemAvatar>
						<Avatar className={classes.avatar}>
							<Resume className={classes.icon} />
						</Avatar>
					</ListItemAvatar>
					<ListItemText classes={{ primary: classes.root }}>Resume</ListItemText>
				</ListItem>
				<ListItem button>
					<ListItemAvatar>
						<Avatar className={classes.avatar}>
							<Portfolio className={classes.icon} />
						</Avatar>
					</ListItemAvatar>
					<ListItemText classes={{ primary: classes.root }}>Portfolio</ListItemText>
				</ListItem>
			</List>
		</>
	)
}

export default NavMenu
