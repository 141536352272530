import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import HeroImage from '../img/hero.jpg'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh',
	},
	image: {
		backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.1)), url(${HeroImage})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		width: '100%',
		height: '100%',
		color: theme.palette.common.white,
	},
}))

export default function SignInSide() {
	const { root, image } = useStyles()

	return (
		<Grid container className={root}>
			<Grid container className={image} justify={'center'} alignItems={'center'} direction={'column'}>
				<Typography variant={'h1'}>Portfolio Website</Typography>
				<Typography variant={'h2'}>Under Construction</Typography>
			</Grid>
		</Grid>
	)
}
