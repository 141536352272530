import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import leoProPic from '../img/leo-pro.JPG'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: theme.spacing(2),
	},
	avatar: {
		width: theme.spacing(15),
		height: theme.spacing(15),
	},
	avatarBg: {
		width: theme.spacing(17),
		height: theme.spacing(17),
		backgroundColor: '#2c2f3f',
	},
}))

const AvatarCard = () => {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<Avatar className={classes.avatarBg}>
				<Avatar className={classes.avatar} src={leoProPic} />
			</Avatar>
		</div>
	)
}

export default AvatarCard
