import React from 'react'
import SideBar from './Components/SideBar'
import Hero from './Components/Hero'

const App = () => {
	return (
		<React.Fragment>
			<SideBar>
				<Hero />
			</SideBar>
		</React.Fragment>
	)
}

export default App
